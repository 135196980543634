import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect, useRef } from 'react';
import { TFunction, useTranslation } from 'next-i18next';
import styles from './CountrySelect.module.scss';

const getLocaleData: (t: TFunction) => {
  [locale: string]: { name: string };
} = (t: any) => ({
  'en-us': { name: t('english') },
  ar: { name: t('arabic') },
  nl: { name: t('dutch') },
  fr: { name: t('french') },
  de: { name: t('german') },
  it: { name: t('italian') },
  es: { name: t('spanish') },
});

const CountrySelect = React.memo(() => {
  const { t } = useTranslation('common');
  const { locale, locales, asPath, push } = useRouter();

  // The locale <select> is always as wide as its widest option,
  // but we only want it to be as wide as the currently selected option.
  // So we render a hidden <select> that only has one <option> (the currently
  // selected locale) and use that to set the width of the locale <select>
  const countrySelectRef = useRef<HTMLSelectElement>(null);
  const hiddenSelectRef = useRef<HTMLSelectElement>(null);

  useEffect(() => {
    const ro = new ResizeObserver(() => {
      if (countrySelectRef.current && hiddenSelectRef.current?.clientWidth) {
        countrySelectRef.current.style.setProperty(
          'width',
          `${hiddenSelectRef.current.clientWidth}px`
        );
      }
    });

    if (hiddenSelectRef.current) {
      ro.observe(hiddenSelectRef.current);
    }

    return () => {
      ro.disconnect();
    };
  }, []);

  useEffect(() => {
    const dir = locale == 'ar' ? 'rtl' : 'ltr';
    document.querySelector('html')?.setAttribute('dir', dir);
  }, [locale]);

  const onCountryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newLocale = e.target.value;
    if (newLocale.startsWith('http')) {
      window.location.href = newLocale;
    } else if (newLocale !== locale) {
      push(asPath, asPath, { locale: newLocale });
    }
  };

  const localeData = getLocaleData(t);

  return (
    <div className={styles.countrySelectContainer}>
      <div className={styles.countrySelectWrapper}>
        <div className={styles.countrySelectOverlay} />

        <label htmlFor="select-country" className={styles.globeAlt}>
          <span className={styles.visuallyHidden}>{t('Select country')}</span>
        </label>
        <select
          id="select-country"
          name="select-country"
          className={styles.countrySelect}
          ref={countrySelectRef}
          value={locale}
          aria-label="language select"
          onChange={onCountryChange}
          data-testid={'country-select'}
        >
          {locales?.map(value => (
            <option key={value} value={value}>
              {localeData[value]?.name}
            </option>
          ))}
        </select>

        {/* use a hidden <select> to find the correct width of the visible country <select> */}
        <label
          htmlFor="select-country-hidden"
          className={styles.visuallyHidden}
        >
          {t('Select country')}
        </label>
        <select
          id="select-country-hidden"
          name="select-country-hidden"
          className={`${styles.countrySelect} ${styles.hiddenSelect}`}
          ref={hiddenSelectRef}
        >
          <option value={locale}>{localeData[locale as string]?.name}</option>
        </select>
        <div
          className={styles.countrySelectPopup}
          data-testid={'country-select-popup'}
        >
          {locales?.map(value => (
            <Link href={asPath} key={value} locale={value}>
              <a className={styles.countrySelectorLink} hrefLang={value}>
                <span>{localeData[value]?.name}</span>
              </a>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
});

CountrySelect.displayName = 'CountrySelect';

export default React.memo(CountrySelect);
