import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import styles from './Header.module.scss';
import { SkipNavLink, SkipNavContent } from '@reach/skip-nav';
import { clsx } from '@wonderful/wwc/dist/src/helpers/clsx';
import WonderfulPistachiosLogo from '../WonderfulPistachiosLogo';
import WonderfulAlmondsLogo from '../WonderfulAlmondsLogo';
import '@reach/skip-nav/styles.css';
import { useTranslation } from 'next-i18next';
import { useIsAlmondPage } from '@helpers/utils';
import CountrySelect from '@static/CountrySelect/CountrySelect';

const getHeaderLinks = (t: Function) => [
  { href: '/products', title: t('products') },
  { href: '/where-to-buy', title: t('where to buy') },
  { href: '/who-we-are', title: t('who we are') },
];

// height of the desktop header when the page is scrolled - this should
// be the same as the value in ./Header.module.scss
const DESKTOP_STUCK_HEADER_HEIGHT = 76;

export default function Header() {
  const [stuckToTop, setStuckToTop] = useState(false);
  const [hidden, setHidden] = useState(false);
  const [disableTransitions, setDisableTransitions] = useState(false);
  const lastScroll = useRef<number>(0);
  const { t } = useTranslation('common');
  const isAlmondPage = useIsAlmondPage();

  useEffect(() => {
    if (typeof window === 'undefined') return;
    const moveNavOnScroll = () => {
      setStuckToTop(window.scrollY > 175);
      setDisableTransitions(
        window.scrollY > lastScroll.current && window.scrollY < 250
      ); // remove initial transition
      setHidden(window.scrollY > 175 && window.scrollY > lastScroll.current);
      lastScroll.current = window.scrollY;
    };
    window.addEventListener('scroll', moveNavOnScroll, { passive: true });
    moveNavOnScroll();
    return () => {
      window.removeEventListener('scroll', moveNavOnScroll);
    };
  }, []);

  const [isVisibleMobile, setIsVisibleMobile] = useState(false);
  useEffect(() => {
    const setVisibleMobileFalse = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setIsVisibleMobile(false);
      }
    };
    document.addEventListener('keydown', setVisibleMobileFalse);

    return () => {
      document.removeEventListener('keydown', setVisibleMobileFalse);
    };
  }, []);

  const navLinksRef = useRef<HTMLUListElement>(null);
  useEffect(() => {
    // Unless we prevent it in this resize listener, the navLinks will animate their transition
    // from visibility to non-visibility when a user resizes the window.
    if (typeof window === 'undefined') return;
    let prevTimeout: NodeJS.Timeout | undefined;
    const onResize = () => {
      if (prevTimeout) clearTimeout(prevTimeout);
      navLinksRef.current?.style.setProperty('transition', 'none');
      prevTimeout = setTimeout(() => {
        navLinksRef.current?.style.removeProperty('transition');
      }, 200);
    };
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
      if (prevTimeout) clearTimeout(prevTimeout);
    };
  }, []);

  const { asPath, events, locale } = useRouter();

  useEffect(() => {
    if (isVisibleMobile) {
      const closeMenu = () => {
        setIsVisibleMobile(false);
      };
      events.on('routeChangeComplete', closeMenu);
      return () => {
        events.off('routeChangeComplete', closeMenu);
      };
    }
  }, [events, isVisibleMobile]);

  const headerRef = useRef<HTMLElement>(null);
  // If header is not hidden, then if something gets focused behind the sticky header,
  // scroll the page so that the header is above the focused item
  useEffect(() => {
    if (!hidden) {
      if (typeof window === 'undefined') return;
      const onFocusElement = (e: FocusEvent) => {
        if (
          !e.target ||
          !(e.target instanceof HTMLElement) ||
          headerRef.current?.contains(e.target)
        ) {
          return;
        }
        const y = e.target.getBoundingClientRect().y;
        if (y < DESKTOP_STUCK_HEADER_HEIGHT) {
          window.scrollBy({ top: -DESKTOP_STUCK_HEADER_HEIGHT });
        }
      };
      document.addEventListener('focusin', onFocusElement);
      return () => {
        document.removeEventListener('focusin', onFocusElement);
      };
    }
  }, [hidden]);

  return (
    <>
      <SkipNavLink />

      <div className={styles.headerSpacer} />
      <header
        className={clsx(
          styles.header,
          stuckToTop ? styles.scrolled : '',
          disableTransitions ? styles.disableTransitions : '',
          hidden ? styles.hidden : ''
        )}
        ref={headerRef}
      >
        <div className="container">
          <Link href="/" locale={locale}>
            <a
              className={styles.logoLink}
              aria-label={
                isAlmondPage
                  ? 'Wonderful Almonds Logo'
                  : 'Wonderful Pistachios Logo'
              }
            >
              {isAlmondPage ? (
                <WonderfulAlmondsLogo
                  className={clsx(styles.logo, styles.almondsLogo)}
                />
              ) : (
                <WonderfulPistachiosLogo className={styles.logo} />
              )}
            </a>
          </Link>
          <nav className={styles.nav}>
            <button
              className={styles.openNavButton}
              aria-expanded={isVisibleMobile}
              onClick={() => {
                setIsVisibleMobile(visible => !visible);
              }}
              data-testid="open-mobile-menu"
            >
              {isVisibleMobile ? <CloseNavButtonIcon /> : <OpenNavButtonIcon />}
            </button>
            <div className={clsx(styles.topLogo, styles.desktopOnly)}>
              <Link href="/" locale={locale}>
                <a
                  tabIndex={stuckToTop ? -1 : 0}
                  aria-label={
                    isAlmondPage
                      ? 'Wonderful Almonds Logo'
                      : 'Wonderful Pistachios Logo'
                  }
                  data-testid="header-logo"
                >
                  {isAlmondPage ? (
                    <WonderfulAlmondsLogo
                      className={clsx(styles.logo, styles.almondsLogo)}
                    />
                  ) : (
                    <WonderfulPistachiosLogo className={styles.logo} />
                  )}
                </a>
              </Link>
            </div>
            <ul
              className={`${styles.navLinks} ${
                isVisibleMobile ? styles.visibleMobile : ''
              }`}
              ref={navLinksRef}
              data-testid="mobile-menu"
            >
              <li className={clsx(styles.topLogo, styles.mobileOnly)}>
                <Link href="/" locale={locale}>
                  <a
                    aria-label={
                      isAlmondPage
                        ? 'Wonderful Almonds Logo'
                        : 'Wonderful Pistachios Logo'
                    }
                    data-testid="mobile-logo"
                  >
                    {isAlmondPage ? (
                      <WonderfulAlmondsLogo
                        className={clsx(styles.logo, styles.almondsLogo)}
                      />
                    ) : (
                      <WonderfulPistachiosLogo className={styles.logo} />
                    )}
                  </a>
                </Link>
              </li>
              <li
                className={clsx(
                  styles.navLinkInnerContainer,
                  styles.navLinkInnerContainerFirst
                )}
              >
                <ul data-testid="nav-menu">
                  {getHeaderLinks(t).map(link => renderNavLinks(link, asPath))}
                  <li className={styles.navLink}>
                    <CountrySelect key="country-select" />
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </header>
      <SkipNavContent />
    </>
  );
}

const renderNavLinks = (
  link: { href: string; title: string },
  asPath: string
) => (
  <li
    key={link.href}
    className={`${styles.navLink} ${
      // give a link hover styles if we're at that path
      link.href === asPath ? styles.activePath : ''
    }`}
  >
    <Link href={link.href} scroll>
      <a>{link.title}</a>
    </Link>
  </li>
);

function OpenNavButtonIcon() {
  const { t } = useTranslation('common');
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 40 40"
      xmlSpace="preserve"
    >
      <g>
        <title>{t('Open Nav Menu')}</title>
        <ellipse id="svg_2" cx="20" cy="20" rx="19.5" ry="19.5" />
        <line id="svg_4" x1="13" y1="19.5" x2="27" y2="19.5" />
        <line id="svg_4_1_" x1="13" y1="23.5" x2="27" y2="23.5" />
        <line id="svg_4_2_" x1="13" y1="15.5" x2="27" y2="15.5" />
      </g>
    </svg>
  );
}

function CloseNavButtonIcon() {
  const { t } = useTranslation('common');
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 40 40"
      xmlSpace="preserve"
    >
      <g>
        <title>{t('Close Nav Menu')}</title>
        <ellipse id="svg_2" cx="20" cy="20" rx="19.5" ry="19.5" />
        <line id="svg_4" x1="12" y1="12" x2="28" y2="28" />
        <line id="svg_4_1_" x1="12" y1="28" x2="28" y2="12" />
      </g>
    </svg>
  );
}
