export default function WonderfulAlmondsLogo(props: { className?: string }) {
  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 612.96 220"
      width="114"
      height="35"
      xmlSpace="preserve"
      className={props.className}
    >
      <style>{'.st0{fill:#ffffff}'}</style>
      <path
        id="XMLID_66_"
        className="st0"
        d="M503.29 28.21c0-3.79 3.07-6.78 6.95-6.78 3.87 0 6.94 2.99 6.94 6.78 0 3.77-3.07 6.79-6.94 6.79-3.88 0-6.95-3.02-6.95-6.79m1.14 0c0 3.19 2.64 5.67 5.82 5.67 3.17 0 5.81-2.48 5.81-5.67 0-3.21-2.65-5.67-5.81-5.67-3.18 0-5.82 2.46-5.82 5.67m8.98 3.86h-1.28l-1.99-3.52h-1.45v3.51h-1.13v-7.63h3.28c1.41 0 2.68.42 2.68 2.02 0 1.51-1.04 2.05-2.07 2.09l1.96 3.53zm-4.72-6.52v1.9h1.2c1.05 0 2.5.17 2.5-.87 0-.97-.83-1.04-1.76-1.04h-1.94z"
      />
      <g id="XMLID_34_">
        <path
          id="XMLID_65_"
          className="st0"
          d="M118.6 25.08h7.56l13.07 47.75h.15l14.14-47.75h7.64l14.05 47.75h.16l13.32-47.75h7.13l-16.88 57.48h-7.39l-14.21-48.72h-.16l-14.29 48.72h-7.47z"
        />
        <path
          id="XMLID_62_"
          className="st0"
          d="M230.66 61.29c.73-3.49.24-6.42-1.47-8.69-1.46-1.87-3.57-3.17-5.84-2.92-4.47.4-7.38 7.15-7.38 7.15s-2.93-6.75-7.4-7.15c-2.34-.25-4.38 1.05-5.84 2.92-1.71 2.28-2.19 5.21-1.46 8.69 2.59 12.01 14.12 16.07 14.7 16.16.47-.09 12.1-4.14 14.69-16.16zm3.25-12.83c2.68 4.06 3.57 9.49 2.44 14.21-3.74 15.83-19.73 20.78-20.37 20.96-.66-.18-16.65-5.12-20.39-20.96-1.14-4.72-.24-10.15 2.44-14.21 2.35-3.41 5.76-5.44 10.23-5.28 4.7.16 7.72 4.3 7.72 4.3s3-4.15 7.7-4.3c4.46-.16 7.88 1.87 10.23 5.28z"
        />
        <path
          id="XMLID_61_"
          className="st0"
          d="M247.56 53.58c0-3.5-.34-6.49-.34-9.02h6.01c0 2.05.17 4.15.17 6.26h.16c1.7-3.73 6.41-7.23 12.67-7.23 9.98 0 14.45 6.33 14.45 15.51v23.47h-6.33V59.75c0-6.34-2.75-10.32-8.69-10.32-8.21 0-11.77 5.93-11.77 14.63v18.5h-6.33V53.58z"
        />
        <path
          id="XMLID_58_"
          className="st0"
          d="M310.8 49.43c-8.19 0-13.47 6.17-13.47 14.13 0 7.96 5.28 14.13 13.47 14.13 8.21 0 13.48-6.17 13.48-14.13 0-7.96-5.27-14.13-13.48-14.13zm13.48 26.39h-.16c-2.35 4.29-7.81 7.71-14.62 7.71-10.64 0-19-8.61-19-19.97 0-11.54 8.04-19.98 19.97-19.98 5.53 0 10.15 2.44 13.64 6.49h.16v-28.9h6.33v61.39h-6.33v-6.74z"
        />
        <path
          id="XMLID_39_"
          className="st0"
          d="M370.83 60.16c-.26-6.42-4.47-10.73-11.44-10.73-6.98 0-11.61 4.31-12.42 10.73h23.86zm5.45 15.49c-4.48 5.77-9.81 7.88-16.89 7.88-11.94 0-19.28-8.93-19.28-19.97 0-11.77 8.31-19.98 19.52-19.98 10.73 0 18.03 7.32 18.03 19.9v2.03h-30.69c.48 6.58 5.52 12.18 12.42 12.18 5.43 0 9.09-1.95 12.09-5.77l4.8 3.73z"
        />
        <path
          id="XMLID_38_"
          className="st0"
          d="M387.48 53.58c0-3.5-.33-6.49-.33-9.02h6.01c0 2.05.16 4.15.16 6.26h.18c1.7-3.73 6.4-7.23 12.65-7.23.82 0 1.72.08 2.6.42l-.4 6.41c-1.06-.33-2.27-.49-3.41-.49-7.72 0-11.12 5.43-11.12 14.14v18.5h-6.34V53.58z"
        />
        <path
          id="XMLID_37_"
          className="st0"
          d="M419.31 49.92h-8.29v-5.37h8.29v-8.51c0-9.25 3.24-15.83 13.47-15.83 1.63 0 3.66.32 4.89.96l-.89 5.7c-1.39-.5-2.85-.82-4.31-.82-6.57 0-6.84 4.79-6.84 11.36v7.15h9.27v5.37h-9.27v32.64h-6.33V49.92z"
        />
        <path
          id="XMLID_36_"
          className="st0"
          d="M474.77 73.56c0 3.49.35 6.5.35 9h-6.03c0-2.03-.15-4.14-.15-6.23h-.19c-1.69 3.73-6.41 7.21-12.66 7.21-9.99 0-14.45-6.34-14.45-15.51V44.55h6.33v22.84c0 6.33 2.77 10.3 8.68 10.3 8.21 0 11.79-5.93 11.79-14.62V44.55h6.33v29.01z"
        />
        <path
          id="XMLID_35_"
          className="st0"
          d="M487.36 21.17h6.32v61.39h-6.32z"
        />
      </g>
      <path
        d="M66.27 190.39h-8.64l33.45-77.4h7.65l33.23 77.4h-8.75l-8.53-20.33H74.69l-8.42 20.33zm11.37-27.55h34.11L94.7 121.73l-17.06 41.11zm65.93 20.55h36.08v7H135.7v-77.4h7.87v70.4zm80.14-6.34h.11l25.91-64.06h12.03v77.4h-7.87v-67.23h-.22l-27.99 67.23h-4.15l-27.99-67.23h-.22v67.23h-7.87v-77.4h12.03l26.23 64.06zm87.13 15.31c-23.07 0-40.34-17.16-40.34-40.67 0-23.5 17.27-40.67 40.34-40.67 23.07 0 40.34 17.16 40.34 40.67 0 23.5-17.27 40.67-40.34 40.67zm0-74.45c-19.9 0-32.03 15.74-32.03 33.78s12.14 33.67 32.03 33.67c19.9 0 32.03-15.63 32.03-33.67s-12.13-33.78-32.03-33.78zm103.97 61h.22v-65.92h7.87v77.4h-9.84l-45.04-66.58h-.22v66.58h-7.87v-77.4h9.84l45.04 65.92zm19.9-65.92h25.69c19.35 0 40.67 12.9 40.67 38.7 0 25.8-21.32 38.7-40.67 38.7h-25.69v-77.4zm7.88 70.4h15.63c23.29 0 34.55-15.74 34.55-31.7 0-15.96-11.26-31.7-34.55-31.7h-15.63v63.4zm104.73-57.94c-3.28-4.7-8.64-7.76-15.96-7.76-7.65 0-16.51 4.59-16.51 14.54 0 9.84 8.2 12.35 16.84 15.2 10.93 3.61 22.96 7.11 22.96 22.63s-13.01 22.3-25.25 22.3c-9.4 0-18.8-3.83-24.38-11.48l6.78-5.03c3.39 5.47 9.62 9.73 17.71 9.73 7.98 0 17.16-5.03 17.16-14.98 0-10.82-9.08-13.56-18.91-16.62-10.28-3.17-20.77-7.65-20.77-21.65 0-14.98 13.34-21.32 24.71-21.32 10.39 0 18.15 4.37 21.86 9.4l-6.24 5.04z"
        className="st1"
      />
    </svg>
  );
}
